
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './views/components/Navbar';
import Api from './api/Api';
import SessionExpiredModal from './views/modal/sessionExpired';
import MatchCenter from './views/main/MatchCenter/MatchCenter';
import DrawAndResult from './views/main/drawAndResult';
import PageBackground from './PageBackground';
import LiveScorePage from './views/main/LandingPage/LiveScorePage';
import CertificateGenrationPage from './views/main/LandingPage/CertificateGenrationPage';
import BibManagementPage from './views/main/LandingPage/BibManagementPage';
import AuctionBayPage from './views/main/LandingPage/AuctionBayPage';
import CreativeMakingPage from './views/main/LandingPage/CreativeMaking';
import ContactUsPage from './views/main/LandingPage/ContactUsPage';
const Home = lazy(() => import('./views/main/Home'));
const Register = lazy(() => import('./views/main/Register'));
const Login = lazy(() => import('./views/main/Login'));


// import Home from './views/main/Home'

const AllCategoriesPage = lazy(() => import('./views/main/AllCategoriesPage'));
const BrowseTournament = lazy(() => import('./views/main/BrowseTournament'));
const NotificationPage = lazy(() => import('./views/main/NotificationPage'));
const CreateTournament = lazy(() => import('./views/createTournament/CreateTournament'));

const Tournament = lazy(() => import('./views/main/tournament'));
const ManageTournament = lazy(() => import('./views/ManageTournament/manageTournament'));
const ManageBooking = lazy(() => import('./views/ManageTournament/manageBooking'));
const ManageCollection = lazy(() => import('./views/ManageTournament/manageCollection'));
const CommunicationTool = lazy(() => import('./views/ManageTournament/communicationTool'));
const PlayerCheckout = lazy(() => import('./views/main/playerCheckout'));
const EntryFee = lazy(() => import('./views/playerCheckout/entryFee'));
const PlayerDetails = lazy(() => import('./views/playerCheckout/details'));
const BookingDetail = lazy(() => import('./views/ManageTournament/bookingDetail'));
const PrizeMoney = lazy(() => import('./views/playerCheckout/prizeMoney'));
const TournamentGallery = lazy(() => import('./views/playerCheckout/tournamentGallery'));
const DetailsForm = lazy(() => import('./views/playerCheckout/components/detailsForm'));
const TicketList = lazy(() => import('./views/playerCheckout/components/ticketList'));
const TermsConditions = lazy(() => import('./views/userInfo/termsConditions'));
const PrivacyPolicy = lazy(() => import('./views/userInfo/privacyPolicy'));
const RefundPolicy = lazy(() => import('./views/userInfo/refundPolicy'));
const ErrorBoundary = lazy(() => import('./errorBoundary'));
const OrganizerProfile = lazy(() => import('./views/organizerProfile/OrganizerProfile'));
const ForgetPassword = lazy(() => import('./views/main/forgot/forgotPassword'));
const GetVerification = lazy(() => import('./views/main/forgot/getVerification'));
const ContactUs = lazy(() => import('./views/main/ContactUs'));
const FeaturesandPricing = lazy(() => import('./views/main/FeaturesandPricing'));
const ManagementSystem = lazy(() => import('./views/main/ManagementSystem'));
const ManagementFeatures = lazy(() => import('./views/main/ManagementFeatures'));
const AboutZemo = lazy(() => import('./views/main/AboutZemo'));
const Feed = lazy(() => import('./views/main/Feed'));
const TournamentManagement = lazy(() => import('./views/main/LandingPage/TournamentManagement'));

function App() {
  const [showSessionModal, setShowSessionModal] = useState(false);
  useEffect(() => {
    Api.setSessionExpiredCallback(() => setShowSessionModal(true));
  }, []);
  const handleClose = () => setShowSessionModal(false);

  return (
    <ErrorBoundary>
      <Router>
       <SessionExpiredModal show={showSessionModal} onClose={handleClose}  />
        <Navbar />
        <Suspense fallback={<div className='loadingDiv'>Loading...</div>}>
          <PageBackground/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/termsConditions' element={<TermsConditions />} />
            <Route path='/privacyPolicy' element={<PrivacyPolicy />} />
            <Route path='/refundPolicy' element={<RefundPolicy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/getverificationCode" element={<GetVerification />} />
            <Route path="/register" element={<Register />} />
            <Route path="/browse-tournaments" element={<BrowseTournament />} />
            <Route path="/all-categories" element={<AllCategoriesPage />} />
            <Route path="/notifications" element={<NotificationPage />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/featuresandPricing" element={<FeaturesandPricing />} />
            <Route path="/aboutZemo" element={<AboutZemo />} />
            <Route path="/feed" element={<Feed />} />
            <Route path="/tournamentManagement" element={<TournamentManagement />} />
            <Route path="/liveScore" element={<LiveScorePage />} />
            <Route path="/certificateGenration" element={<CertificateGenrationPage />} />
            <Route path="/creativeMaking" element={<CreativeMakingPage />} />
            <Route path="/bibManagement" element={<BibManagementPage />} />
            <Route path="/auctionBay" element={<AuctionBayPage />} />
            <Route path="/contactUsPage" element={<ContactUsPage />} />
            {/* <Route path="/my-booking" element={<Player/>}/> */}
            <Route path="/createTournament/*" element={<CreateTournament />} />
            <Route path="/organiserProfile/*" element={<OrganizerProfile />} />
            <Route path='/tournament' element={<Tournament />} >
              <Route path="/tournament" element={<ManageTournament />} />
              <Route path="/tournament/manageBooking" element={<ManageBooking />} />
              <Route path="/tournament/manageCollection" element={<ManageCollection />} />
              <Route path="/tournament/communicationTool" element={<CommunicationTool />} />
            </Route>
          <Route path="/bookingDetail" element={<BookingDetail />} />
            <Route path="/my-booking" element={<PlayerCheckout />}>
              <Route path="/my-booking/:id" element={<PlayerDetails />} />
              <Route path="/my-booking/entryfee" element={<EntryFee />} />
              <Route path="/my-booking/details/:id" element={<PrizeMoney />} />
              <Route path="/my-booking/gallery" element={<TournamentGallery />} />
              <Route path="/my-booking/matchCenter" element={<MatchCenter />} />
              <Route path="/my-booking/drawAndResult/:id" element={<DrawAndResult />} />
            </Route>
            <Route path='/details-form' element={<DetailsForm />} />
            <Route path='/ticket-list' element={<TicketList />} />
            <Route path='managementSystem' element={<ManagementSystem />} />
            <Route path="managementFeatures" element={<ManagementFeatures />} />
            
            
            {/* <Route path='/detail' element={<details/>}/> */}
          </Routes>
        </Suspense>
        {/* <Tournament/> */}
      </Router>
    </ErrorBoundary>
  );
}

export default App;

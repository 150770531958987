let Constants = {
    GET_MANAGE_TOURNAMENTS: "organizor/manageTournaments?",
    GET_BOOKINGS: "organizor/bookings?",
    SET_COMMUNICATION: "organizor/communication",
    GET_PLAYERS_LIST: "players/list",
    GET_TICKET_STATUS: "organizor/ticketStatus",
    GET_COMMISSIONS: "organizor/commissions?",
    GET_CATEGORIES: "categories",
    UPDATE_TOURNAMENT_BOOKING_STATUS: "updateTournamentBookingStatus",
    REMOVE_SPORT: "removeSport?",
    UPDATE_PROFILE: "updateProfile",
    UPDATE_PROFILE_PICTURE: "updateProfilePicture",
    CHNAGE_PASSWORD: "changePassword",
    UPDATE_BANKDETAILS: "updateBankDetails",
    GET_BANKDETAIL: "bankDetails",
    GET_PROFILE: "profile", 
    GET_TICKETS_LIST: "tickets",
    GET_DRAW_AND_RESULTS: "getDrawAndResultsOfTournament"
  };
  
  export default Constants;
  
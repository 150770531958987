import React, { useEffect, useState } from 'react';
import config from '../../../config/config';
import Api from '../../../api/Api';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getTournamentType } from '../../../store/tournamentTypeSlice';
const env = process.env.REACT_APP_ENV || "qa";

const MatchCenter = () => {
    // const [tournamentId, setTournamentId] = useState(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const ZEMO_SUITE_URL = config[env].ZEMO_SUITE_URL;
    let tournamentId = localStorage.getItem('selectedTournamentId')
    const [tournamentView, setTournamentView] = useState()
    const [categoryID, setCategoryID] = useState()

    useEffect(() => {
        const hash = window.location.hash;
        const queryParams = new URLSearchParams(hash.split('?')[1]);
        const idFromUrl = queryParams.get('tournamentId');
        localStorage.setItem('selectedTournamentId', idFromUrl)
    }, [tournamentId]);


    // api call for details
    const getTournamentDetails = async () => {
        // Proceed with API call
        await Api.call(`tournamentDetails?id=${tournamentId}`, "GET")
            .then(async (response) => {
                if (response) {
                    setCategoryID(response.category_id)
                    const now = moment(); // Current date and time
                    const startDate = moment(response.start_date, "YYYY-MM-DD");
                    const endDate = moment(response.end_date, "YYYY-MM-DD");
                    const startTime = moment(response.start_time, "HH:mm:ss");
                    const endTime = moment(response.end_time, "HH:mm:ss");
                    if (now.isBefore(startDate)) {
                        localStorage.setItem("selectedTournamentType", "upComing");
                        dispatch(getTournamentType("upComing"))
                    } else if (now.isSameOrAfter(startDate) && now.isSameOrBefore(endDate)) {
                        // On the start date or between start and end dates
                        if (now.isBefore(moment(`${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm:ss")}`))) {
                            // Before start time on the start date
                            localStorage.setItem("selectedTournamentType", "upComing");
                            dispatch(getTournamentType("upComing"))
                        } else if (now.isBetween(
                            moment(`${startDate.format("YYYY-MM-DD")} ${startTime.format("HH:mm:ss")}`),
                            moment(`${endDate.format("YYYY-MM-DD")} ${endTime.format("HH:mm:ss")}`),
                            undefined,
                            "[]"
                        )) {
                            localStorage.setItem("selectedTournamentType", "onGoing");
                            dispatch(getTournamentType("onGoing"))
                        } else if (now.isAfter(moment(`${endDate.format("YYYY-MM-DD")} ${endTime.format("HH:mm:ss")}`))) {
                            localStorage.setItem("selectedTournamentType", "onGoing");
                            dispatch(getTournamentType("onGoing"))
                        }
                    } else if (now.isAfter(endDate)) {
                        localStorage.setItem("selectedTournamentType", "recentlyConclud");
                        dispatch(getTournamentType("recentlyConclud"))
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        if (tournamentId) {
            getTournamentDetails()
        }
    }, [tournamentId])

    const handleIframeLoad = () => {
        setLoading(false);
    };



    return (
        <>
            {loading && (
                <div className="loader">
                    <div class="spinner-border text-primary " role="status">
                        <span class="sr-only"></span>
                    </div>
                </div>
            )}
            {console.log(categoryID , "categoryID ")}
            <iframe
                src={
                    ([4,16,28].includes(categoryID))
                        ? `${ZEMO_SUITE_URL}/matchCenter?tournamentId=${tournamentId}`
                        : `${ZEMO_SUITE_URL}/matchCenter/leaderBoard?tournamentId=${tournamentId}`
                }
                style={{ width: '100%', border: 'none' }}
                title="Match Center"
                className='machCenterIframe'
                onLoad={handleIframeLoad}
            />
        </>
    );
};

export default MatchCenter;

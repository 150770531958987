import config from "../config/config";
import { toast } from "react-toastify";
import store from "../redux/store"; // Ensure your Redux store import is correct
import { setLoggedIn, setUserRoleId } from "../store/userRoleSlice";

const env = process.env.REACT_APP_ENV || "qa";

export default class Api {

  static sessionExpiredCallback = null;

  static setSessionExpiredCallback(callback) {
    this.sessionExpiredCallback = callback;
  }


  static call(url, method = "GET", data) {
    let BASE_URL = config[env].API_URL;
    let token = localStorage.getItem('token');

    let headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Accept": "application/json",
    };

    // Add Authorization header if token is available
    if (token) {
      headers.Authorization = `bearer ${token}`;
    }

    return new Promise((resolve, reject) => {
      fetch(BASE_URL + url, {
        method: method || "GET",
        body: JSON.stringify(data),
        mode: "cors",
        headers: headers,
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          console.log(response.status);
          switch (response.status) {
            case 404:
              console.log("Object not found");
              break;
            case 401:
              localStorage.clear();
              Api.handleLogout();
              if (this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
              }
              break;
            case 500:
              console.log("Internal server error");
              break;
            default:
              console.log("Some error occurred");
              break;
          }
          resolve(response.json());
        }
      })
      .catch((error) => {
        console.log(error);
        reject("Error");
      });
    });
  }

  static CoreApi(url, method = "GET", data) {
    let BASE_URL = config[env].API_URL;
    return new Promise((resolve, reject) => {
      fetch(BASE_URL + url, {
        method: method || "GET",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          console.log(response.status);
          switch (response.status) {
            case 404:
              console.log("Object not found");
              break;
            case 401:
              localStorage.clear();
              Api.handleLogout();
              if (this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
              }
              break;
            case 500:
              console.log("Internal server error");
              break;
            default:
              console.log("Some error occurred");
              break;
          }
          resolve(response.json());
        }
      })
      .catch((error) => {
        console.log(error);
        reject("Error");
      });
    });
  }

  static CoreApiToken(url, method = "GET", data) {
    let BASE_URL = config[env].API_URL;
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      fetch(BASE_URL + url, {
        method: method || "GET",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          switch (response.status) {
            case 404:
              console.log("Object not found");
              break;
            case 401:
              localStorage.clear();
              Api.handleLogout();
              if (this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
              }
              break;
            case 500:
              console.log("Internal server error");
              break;
            default:
              console.log("Some error occurred");
              break;
          }
          response.json().then((json) => {
            return reject(json);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        reject("Error");
      });
    });
  }

  static ScoringApiToken(url, method = "GET", data) {
    let BASE_URL = config[env].API_URL_SCORING;
    const token = localStorage.getItem("token");
    return new Promise((resolve, reject) => {
      fetch(BASE_URL + url, {
        method: method || "GET",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else {
          switch (response.status) {
            case 404:
              console.log("Object not found");
              break;
            case 401:
              localStorage.clear();
              Api.handleLogout();
              if (this.sessionExpiredCallback) {
                this.sessionExpiredCallback();
              }
              break;
            case 500:
              console.log("Internal server error");
              break;
            default:
              console.log("Some error occurred");
              break;
          }
          response.json().then((json) => {
            return reject(json);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        reject("Error");
      });
    });
  }

  static handleLogout() {
    // Dispatch logout actions
    store.dispatch(setLoggedIn(false));
    store.dispatch(setUserRoleId(""));
    // window.location.hash = "#/login";
  }
}
